import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import dayjs, { Dayjs } from "dayjs";
import { atcb_action } from "add-to-calendar-button";

function fetchEventData() {
  let currentUrl = window.location.href;
  //local
  if (currentUrl.includes("localhost")) {
    currentUrl = "https://preview-ivenkatasaikumar-e2692e50e4c1.inyt.app/";
  }
  console.log("Current URL:", currentUrl);

  const subdomain = currentUrl.split(".")[0].split("//")[1];
  console.log("Subdomain:", subdomain);
  const clientUrl =
    "https://f18zm0vnug.execute-api.us-east-1.amazonaws.com/client/" +
    subdomain;
  console.log("clientUrl:", clientUrl);
  fetch(clientUrl)
    .then((response) => response.json())
    .then((data) => {
      // store the result in the window object
      window.myData = data;
      console.log("Result", data);
      renderComponents();
    })
    .catch((error) => {
      console.error("Error:", error);
      window.location = "http://inyt.app";
    });
}

const FinalFormattedDate = (rawDate) => {
  let finalDate = dayjs(rawDate).format("MMM D, YYYY h:mm A");
  return finalDate;
};

function renderComponents() {
  // STEP1: groom_and_bride_names
  const groomAndBrideNames = ReactDOM.createRoot(
    document.getElementById("groom_and_bride_names")
  );
  groomAndBrideNames.render(
    <h1>
      {window.myData.details.groom_name} &amp;{" "}
      {window.myData.details.bride_name}
    </h1>
  );

  // STEP2: eventStartTime
  const eventStartTime = ReactDOM.createRoot(
    document.getElementById("event_wedding_time")
  );
  eventStartTime.render(
    <b>{FinalFormattedDate(window.myData.details.event_startDate)}</b>
  );

  //STEP3: eventStartTime and Location
  const eventStartTimeAndLocation = ReactDOM.createRoot(
    document.getElementById("event_wedding_time_and_location")
  );
  eventStartTimeAndLocation.render(
    <h3>
      {FinalFormattedDate(window.myData.details.event_startDate)} @{" "}
      {window.myData.details.event_locationName}
    </h3>
  );

  //Groom https://ik.imagekit.io/inytapp/groom.jpg
  const groomPic = ReactDOM.createRoot(document.getElementById("groom_pic"));
  groomPic.render(
    <img
      src={window.myData.details.groom_pictureUrl}
      alt="groom"
      className="img-responsive"
    />
  );

  const groomName = ReactDOM.createRoot(document.getElementById("groom_name"));
  groomName.render(<h3>{window.myData.details.groom_name}</h3>);

  const groomDetails = ReactDOM.createRoot(
    document.getElementById("groom_details")
  );
  groomDetails.render(<p>{window.myData.details.groom_details}</p>);

  //Bride
  const bridePic = ReactDOM.createRoot(document.getElementById("bride_pic"));
  bridePic.render(
    <img
      src={window.myData.details.bride_pictureUrl}
      alt="groom"
      className="img-responsive"
    />
  );

  const brideName = ReactDOM.createRoot(document.getElementById("bride_name"));
  brideName.render(<h3>{window.myData.details.bride_name}</h3>);

  const brideDetails = ReactDOM.createRoot(
    document.getElementById("bride_details")
  );
  brideDetails.render(<p>{window.myData.details.bride_details}</p>);

  //Location
  const location = ReactDOM.createRoot(document.getElementById("location"));
  location.render(<p>{window.myData.details.event_locationName}</p>);

  // Main background image
  const mainBackgroundImg = document.getElementById("fh5co-header");
  mainBackgroundImg.style.backgroundImage =
    "url('" + window.myData.details.event_backgroundImage + "')";

  // Timer
  setCountdownTimer();

  //Add to Calender
  addToCalendarButton();

  //add gmaps
  addGoogleMaps();
}

function setCountdownTimer() {
  const date = dayjs(window.myData.details.event_startDate);
  window.simplyCountdown(".simply-countdown-one", {
    year: date.year(),
    month: date.month() + 1,
    day: date.date(),
    hours: date.hour(),
    enableUtc: false,
  });
}

function addToCalendarButton() {
  const config = {
    name: `[Reminder] ${window.myData.details.groom_name} &amp; ${window.myData.details.bride_name} Wedding Invitation`,
    startDate: dayjs(window.myData.details.event_startDate).format(
      "YYYY-MM-DD"
    ),
    startTime: dayjs(window.myData.details.event_startDate).format("h:mm"),
    endDate: dayjs(window.myData.details.event_endDate).format("YYYY-MM-DD"),
    endTime: dayjs(window.myData.details.event_endDate).format("h:mm"),
    // endTime: "23:30",
    timeZone: "Asia/Kolkata", //"America/Los_Angeles",
    description: `We wholeheartedly want to invite you and your entire family to attend our wedding ceremony. Come and witness our vows and give us sincere prayers for our further life.:[br]→ [url]https://${window.location.href}`,
    options: ["Google|My custom label"],
  };
  const calenderBtn = document.getElementById("add_to_calender");
  if (calenderBtn) {
    calenderBtn.addEventListener("click", () =>
      atcb_action(config, calenderBtn)
    );
  }
}

function addGoogleMaps() {
  const gmapsEle = ReactDOM.createRoot(
    document.getElementById("gmaps_location")
  );

  const eventLocation = window.myData.details.event_location;

  const lat = eventLocation.lat;
  const lng = eventLocation.lng;
  const srcUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCJmrXNYWI981srP896ywpi7J2SgV15Ofs&q=${lat},${lng}`;
  gmapsEle.render(
    <iframe
      src={srcUrl}
      width="100%"
      height="300"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}

reportWebVitals();
fetchEventData();
